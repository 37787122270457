@media screen and (max-width:900px) {
    .container-login {
            margin-bottom: 100px;
    }
}

.logo-ebbm{
    margin-bottom: 1%;
    width: 50%;
}

.username{
    min-height: 60px;
}

.password{
    min-height: 60px;
}

.verification{
    min-height: 60px;
    margin-bottom: 50px;
    color: #187FDE;
    text-align: center;
    font-weight: bold;
    font-size: 32px;
}

.btn-login {
    width: 100%;
    min-height: 60px;
}

.ellipse{
    width: 100%;
}

@media screen and (min-width:1000px){
    .ellipse{
        width: 60%;
        position: fixed;
        left: 0;
        bottom: 0;
    }
}