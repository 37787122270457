.title-page-fr {
    color: #27248E;
    font-weight: bold;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.box-form {
    border: 3px solid #e9e7e7;
    border-radius: 10px;
    padding: 20px;
}


@media screen and (max-width:650px){
    .input-status-fr {
        min-width: 200px;
        margin-left: 20px;
    }     
    
    .input-unit-number-fr{
        min-width: 200px;
        margin-left: 20px;
        margin-top: 10px;
    }

}

.icon-delete{
    color: #FB5454;
}

.icon-edit{
    color:#4DD4AC
}

.btn-submit {
    height: 25px;
}

@media screen and (min-width:600px){
    .box-sidebar {
        float: right;
    }              
}




