.box-card{
    margin-top: 5%;
    border-bottom: 1px solid #E0E0E0;
    padding-bottom: 5%;
}

.schedule {
    background-color: #187FDE;
    border-radius: 10px;
    text-align: center;
    padding: 10px;
}

.outstanding {
    background-color: #27248E;
    border-radius: 10px;
    text-align: center;
    padding: 10px;
}

.transaction {
    background-color: #6DC8A4;
    border-radius: 10px;
    text-align: center;
    padding: 10px;
    
}

.qty-submit {
    background-color: #B3C65B;
    border-radius: 10px;
    text-align: center;
    padding: 10px;
            }

.qty-outstanding {
    background-color: #8FD0EF;
    border-radius: 10px;
    text-align: center;
    padding: 10px;
                }

.qty-issue {
    background-color: #EB5757;
    border-radius: 10px;
    text-align: center;
    padding: 10px;
                }

.row-card1{
    margin-bottom: 2%;
}

@media screen and (max-width:800px){
    .schedule {
        min-height: 145px;
        min-width: 95px;
        margin-bottom: 2%;        
        }
        
    .outstanding {
        min-height: 145px; 
        min-width: 95px;
        margin-bottom: 2%;
        }
        
    .transaction {
        min-height: 145px;
        min-width: 95px;  
        }
        
    .qty-submit {
        min-height: 145px;
        min-width: 95px;
        margin-bottom: 2%;  
        }
        
    .qty-outstanding {
        min-height: 145px;  
        min-width: 95px;
        margin-bottom: 2%;
        }
        
    .qty-issue {
         min-height: 145px; 
         min-width: 95px;
         margin-bottom: 2%;
        }
                    
}

@media screen and (max-width:1100px){
    .card-text {
        font-size: 13px;
        margin-bottom: 5px;
    }
                    
}


.col-icon {
    margin: auto;
}

.col-text {
    font-weight: bold;
    color: white;
}


p {
    margin: auto;
}

h4 {
    margin-top: 5%;
    margin-bottom: 5%;
    color: #27248E;
}


.box-chart{
    border: 3px solid #e9e7e7;
    border-radius: 10px;
    padding: 10px;
    height: 100%;
}

.chart {
    min-height: 600px;
}

.box-table{
    border: 3px solid #e9e7e7;
    border-radius: 10px;
    height: 100%;
    margin-top: -1px;
}

.table-container{
    width: 100%;
}

@media screen and (max-width:1200px){
    .box-table{
        margin-top: 10px;
    }
                    
}


@media screen and (min-width:600px){
    .box-sidebar {
        float: right;
    }              
}