.title-page-entity {
    color: #27248E;
    font-weight: bold;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.box-new-entity{
    border-bottom: 1px solid #E0E0E0;
    margin-top: 10px;
    margin-bottom: 20px;
}

.table-container{
    margin-top: 20px;
}

