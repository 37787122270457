.title-page-provider {
    color: #27248E;
    font-weight: bold;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.box-form {
    border: 3px solid #e9e7e7;
    border-radius: 10px;
    padding: 20px;
}


@media screen and (max-width:890px){
    .input-provider {
        min-width: 200px;
        margin-left: 35px;
        margin-top: 10px;
    }     
}

.box-new-provider{
    border-bottom: 1px solid #E0E0E0;
    margin-top: 10px;
    margin-bottom: 20px;
}

.table-container{
    margin-top: 20px;
}

