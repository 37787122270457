.title-page-tv {
    color: #27248E;
    font-weight: bold;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.box-form {
    border: 3px solid #e9e7e7;
    border-radius: 10px;
    padding: 20px;
}


.grid-title-search {
    width: 20%;
}

.grid-col-search {
    width: 70%;
}

@media screen and (max-width:495px){
    .grid-title-search {
        width: 25%;
    }

    .grid-col-search {
        width: 50%;
    }

    .input-unit-number-vehicle {
        margin-top: 20px;
    }
                    
}


.box-border-vehicleType{
    border-bottom: 1px solid #E0E0E0;
    margin-top: 20px;
    margin-bottom: 20px;
}

.table-container{
    margin-top: 20px;
}

