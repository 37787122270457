.title-page-ccf {
  color: #27248e;
  font-weight: bold;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

span {
  font-size: 20px;
  margin: auto;
}

.box-cost-center-form {
  border: 3px solid #e9e7e7;
  border-radius: 10px;
  padding: 30px;
  margin-top: 20px;
}

@media screen and (min-width: 600px) {
  .box-sidebar {
    float: right;
  }

  .col-checkbox {
    display: flex;
    justify-content: right;
  }
}
