.menu-profile {
    width: 150px;
}

.menu-setup{
    margin-left: 35px;
}

.menu-master-kendaraan {
    margin-left: 35px;
}

.menu-profile {
    background-color: #084298;
    color: white;
    margin-top: -8px;
    margin-bottom: -8px;

}


