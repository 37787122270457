.title-page-vf {
    color: #27248E;
    font-weight: bold;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}

span {
    font-size: 20px;
    margin: auto;
}

.box-vehicle-form{
    border: 3px solid #e9e7e7;
    border-radius: 10px;
    padding: 30px;
    margin-top: 20px;
 
}

@media screen and (min-width:600px){
    .box-sidebar {
        float: right;
    }              
}